.sketchstrap {
  display: block;
  padding: $spacer;
  color: #fff;
  text-align: center;
  background-color: rgba(132, 64, 135, 1);
  background-image: linear-gradient(to right, rgba(132, 64, 135, 1), rgba(252, 122, 82, 1));

  &:hover {
    color: #fff;
  }
}
