.customers {
  img {
    filter: grayscale(100%);
    opacity: .5;
    &:hover{
      filter: grayscale(0%);
      opacity: 1;
    }
  }
}
