// stylelint-disable declaration-no-important
.page-header {
  position: relative;

  &.about-us {
    background-color: rgba(74, 46, 255, .5);
    background-image: linear-gradient(to bottom right, rgba(74, 46, 255, .5), rgba(28, 152, 255, .5));
    > .container {
      padding: ($spacer * 20) 0;
    }
  }

  &.about-us::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../img/top.jpg"), linear-gradient(to bottom right, #4a2eff, #1c98ff);
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;
  }
}
