//
// Footer
//
.footer {
  padding: 40px 0 0;
  color: #fff;
  background: $gray-800;

  img {
    height: 200px;
  }

  a {
    color: $text-muted;
  }
}

.copyright {
  background: $gray-900;
}
