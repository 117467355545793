.sidebar {
  .template-card {
    margin-bottom: ($spacer * 1.5);
  }

  .template-card-thumb {
    position: relative;
    display: block;
    margin-bottom: ($spacer * .75);
    overflow: hidden;
    text-align: center;

    &:hover {
      .template-card-overlay {
        display: flex;
        align-items: center;
      }
    }
  }

  .template-card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);

    > a {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      width: 100%;
      height: 100%;
    }
  }

  .template-card-actions {
    position: relative;
    z-index: 4;
    .btn {
      width: 48px;
      padding: $btn-padding-y-lg 0;
      text-align: center;
    }
  }

  .template-card-version {
    position: absolute;
    left: 50%;
    display: inline-flex;
    align-items: center;
    padding-right: ($spacer * 1.5);
    padding-left: ($spacer * .5);
    margin-top: -75px;
    color: #fff;
    white-space: nowrap;
    background: #fff;
    border: 2px solid transparent;
    border-radius: 50em;
    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07);
    transform: translate(-50%, 0);


    .template-card-version-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      margin: ($spacer * .25) ($spacer * 1) ($spacer * .25) ($spacer * .25);
      background: #fff;
      border-radius: 50em;

      img {
        max-width: 30px;
        max-height: 30px;
        // margin: 3px;
      }
    }
  }

  .template-card-title {
    font-size: $font-size-base;
  }

  .template-card-price {
    font-size: $font-size-base;
    font-weight: 600;
    line-height: 1.2;
  }

  .template-card-subtitle {
    font-size: $font-size-sm;
    color: $gray-600;
  }

  .template-card-rating {
    font-size: $font-size-sm;
    line-height: 1.2;
    white-space: nowrap;
  }
}
