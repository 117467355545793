// stylelint-disable

$angular:       #ed1c24;
$angularjs:     #e43137;
$bootstrap:     #7952b3;
$coreui:        #1ea6d6;
$react:         #61dafb;
$vue:           #41b782;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$info:     #1ea6d6 !default;

$colors: () !default;
$colors: map-merge(
  (
    "angular":    $angular,
    "angularjs":  $angularjs,
    "bootstrap":  $bootstrap,
    "coreui":     $coreui,
    "react":      $react,
    "vue":        $vue
  ),
  $colors
);

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "angular":   $angular,
    "angularjs": $angularjs,
    "bootstrap": $bootstrap,
    "coreui":    $coreui,
    "react":     $react,
    "vue":       $vue,
    "info":      $info,
    "gray-100":  $gray-100,
    "gray-200":  $gray-200,
    "gray-300":  $gray-300,
    "gray-400":  $gray-400,
    "gray-500":  $gray-500,
    "gray-600":  $gray-600,
    "gray-700":  $gray-700,
    "gray-800":  $gray-800,
    "gray-900":  $gray-900
  ),
  $theme-colors
);

.black-friday {
  background: -webkit-linear-gradient(270deg, #1C1C1C 10%, #494949 270%);
  background: -moz-linear-gradient(270deg, #1C1C1C 10%, #494949 270%);
  background: linear-gradient(270deg, #1C1C1C 10%, #494949 270%);
}

// Load Bootstrap variables and mixins
@import "node_modules/bootstrap/scss/bootstrap";

@mixin background-opacity($parent, $color, $opacity: 0.9) {
  #{$parent} {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
  }
}

@each $color, $value in $theme-colors {
  @include background-opacity(".bg-overlay-#{$color}", $value);
}

// Load docs components
@import "carousel";
@import "customers";
@import "masthead";
// @import "page-header";
// @import "skiplink";
@import "blog";
@import "template-card";
@import "pricing";
@import "footer";
@import "page-header";
@import "component-examples";
// @import "responsive-tests";
// @import "buttons";
// @import "callouts";
// @import "browser-bugs";
// @import "brand";
// @import "colors";
@import "clipboard-js";

@import "sketchstrap";

// Load docs dependencies
@import "syntax";

@include media-breakpoint-up(sm) {
  .text-sm-nowrap {
    white-space: nowrap;
  }
}
