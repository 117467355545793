// stylelint-disable

.table-pricing {
  div[class^='col-md'],
  div[class*=' col-md'] {
    padding: 0 5px;
  }
}
.pricing {
  position: relative;
  padding: $spacer;
  font-size: 14px;
  border: 1px solid $gray-300;
  border-radius: $border-radius;

  &:hover {
    border: 1px solid theme-color("primary");
  }
}

.pricing-most-popular {
  position: absolute;
  top: -26px;
  left: -1px;
  width: calc(100% + 2px);
  padding: $spacer * .25 0;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-color: theme-color("primary");
  border-radius: $border-radius $border-radius 0 0;
}

.pricing-title {
  font-size: 15px;
  font-weight: 700;
  color: $gray-800;
}

.pricing-price {
  font-size: 28px;
  color: theme-color("primary");

  .currency,
  .vat {
    color: $gray-600;
    font-size: 16px;
  }

  .period {
    color: $gray-600;
    font-size: 14px;
  }
}

.pricing-desc {
  padding: $spacer * 2;
}

.pricing-row {
  font-size: 13px;
}

.pricing-row.versions {
  min-height: 264px;
  padding: $spacer;
  margin: 1rem -1rem;

  .d-block {
    margin: -8px 0 5px 0;
  }

  ul {
    margin: 0;
    padding: 0;
  }
  ul.dashed {
    list-style-type: none;
  }
  ul.dashed > li {
    position: relative;
  }
  ul.dashed > li:before {
    position: absolute;
    left: -10px;
    content: "-";
  }
}

.button-container {
  padding: $spacer * 2 0 0 0;
  text-align: center;
}

.pricing-time {
  margin-top: 5px;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: -1px;
}

.iconita {
  height: 52px;
  font-size: 48px;
  line-height: 52px;
}

.pricing-option,
.pricing-desc {
  // font-size: 16px;
  line-height: 1.5;
  color: #666;
  text-align: center;
}
.pricing-option.not {
  text-decoration: line-through;
  opacity: .45;
}

.pricing-option.selected {
  background: #3595f8;
}

.pricing-row.selected .fa {
  top: 0;
  font-size: 13px;
}
.fa {
  position: relative;
  top: -1px;
  margin-right: 10px;
  font-size: 11px;
  color: theme-color("primary");
}

.billing-time {
  margin-top: 8px;
  font-size: 14px;
  line-height: 14px;
  color: #b3b3b3;
}

.secondary-button {
  background: transparent;
  &:hover {
    background: #fff;
  }
  &.secondary-button-inverse:hover {
    background: theme-color("primary");
  }
}

.pricing.inverse {
  background: theme-color("light");
}
