// stylelint-disable no-duplicate-selectors, selector-no-qualifying-type
.carousel.support {
  .carousel-indicators {
    bottom: -30px;
    li {
      cursor: pointer;
      background-color: rgba(0, 0, 0, .15);
      &.active {
        background-color: rgba(0, 0, 0, .35);
      }
    }
  }
}
