// scss-lint:disable ImportantRule

.masthead {
  position: relative;
  padding: 3rem ($grid-gutter-width / 2);
  // background-image: linear-gradient(45deg, #fafafa, #f5f5f5);

  &.bundle {
    height: 600px;
    background-color: #444;
    background-image: url("../img/bundle01.png");
    background-repeat: no-repeat;
    background-size: cover;

    .container {
      position: relative;
      z-index: 3;
    }

    .shadow {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .8);
    }
  }

  h1 {
    line-height: 1;
  }

  .btn {
    width: 100%;
    padding: .8rem 2rem;
    font-size: 1.25rem;
    font-weight: 500;
  }
}

.bd-pretext {
  overflow: hidden;
  resize: none;
}

.half-rule {
  width: 6rem;
  margin: 2.5rem 0;
}

.masthead-followup {
  .bd-clipboard { display: none; }

  .highlight {
    padding: .5rem 0;
    background-color: transparent;
  }
}
