.sidebar-title {
  font-size: 1.25rem;
}

.post {
  padding-bottom: $spacer * 1.5;
  margin-bottom: $spacer * 1.5;
  border-bottom: 1px solid $gray-200;
  &.last {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
  }
  .post-title {
    font-size: 28px;
    a {
      color: $gray-800;
    }
  }
  // stylelint-disable selector-no-qualifying-type
  h4.post-title {
    font-size: 18px;
  }
  .post-meta {
    color: $gray-600;
  }
  .post-thumb {
    width: 100%;
    margin: $spacer 0;
    border-radius: $border-radius;
  }
}
